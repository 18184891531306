import Home from "./Home"
import Translate from "./Translate"
import UsersList from "./UsersList"
import Profile from "./Profile";

const Playground = ({selectedTab}) => {
  return (
    <div className="flex-col col-span-11 w-full h-full">
      <div className="w-full border-b border-b-[#E2E2E3] bg-[#FFF] h-[7%] text-start pl-4 text-[#1C4C96] font-semibold text-xl font-sans flex items-center relative z-10">
        ShabdAI - {selectedTab}
      </div>
      <div className="flex p-4 h-[93%]">
        {selectedTab === "Home" && <Home />}
        {selectedTab === "Translation" && <Translate />}
        {selectedTab === "User Management" && <UsersList />} 
        {selectedTab === "Profile" && <Profile />} 

      </div>
    </div>
  );
};

export default Playground;
