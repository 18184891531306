import React, { useState } from "react";
import FileCabinate from "../../assets/Dashboard/FileCabinate.svg";
import { useDispatch } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";
import {
  setUploadedFile,
  setFileName,
  setInputLanguage,
  setUploadedFileData,
  setFiletype,
} from "../../store/slices/TranslateStore";

const Uploadfile = (props) => {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);

  const handleChange = (event) => {
    dispatch(setInputLanguage(event.target.value));
  };

  const handleFileChange = (selectedFile) => {
    dispatch(setFileName(selectedFile?.name));
    if (selectedFile) {
      dispatch(setFiletype(selectedFile.type));
      if (selectedFile.type === "application/pdf") {
        dispatch(setUploadedFileData(URL.createObjectURL(selectedFile)));
      } else if (selectedFile.type === "text/html") {
        const reader = new FileReader();
        reader.onload = (event) => {
          dispatch(setUploadedFileData(event.target.result));
        };
        reader.readAsText(selectedFile);
      } else {
        dispatch(setUploadedFileData(null));
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile) {
      const fileName = selectedFile?.name.toLowerCase();
      if (
        fileName.endsWith(".doc") ||
        fileName.endsWith(".docx") ||
        fileName.endsWith(".xml")
      ) {
        dispatch(setToast(true));
        dispatch(
          setToastMessage([
            "error",
            "File format not supported, only PDF and HTML are compatible.",
          ])
        );
      }
    }
    handleFileChange(selectedFile);
    dispatch(setUploadedFile(selectedFile));
  };

  const handleFileInput = (e) => {
    const selectedFile = e.target.files[0];
    handleFileChange(selectedFile);
    dispatch(setUploadedFile(selectedFile));
  };

  return (
    <div className="w-full md:w-1/2 h-full gap-6">
      <div className=" w-full h-[70px] flex items-center justify-between">
        <div className="w-[30%] lg:w-[40%] h-full grid items-start">
          <p className="text-[#1C4C9C] font-semibold text-xl flex mt-2 gap-1">
            Source
            <p className="hidden lg:block"> Document</p>
          </p>
          {props.uploadedFileData && (
            <div className="h-full flex ">
              <form className="flex flex-col md:flex-row text-start gap-1">
                <label
                  htmlFor="fileInput"
                  className="text-[#106AF2] cursor-pointer"
                >
                  Browse{" "}
                </label>
                <input
                  type="file"
                  id="fileInput"
                  name="fileInput"
                  onChange={handleFileInput}
                  accept=".pdf, .html"
                  className="w-[98px] bg-transparent"
                  style={{ display: "none" }}
                />
                <p
                  className="w-[20%] lg:w-[40%] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  title={props.fileName}
                >
                  {" "}
                  {props.fileName}
                </p>
              </form>
            </div>
          )}
        </div>
        <div className="w-[60%] grid justify-end mr-[3%]">
          <p
            htmlFor="translate"
            className="text-[#1C4C9C] w-[130px] font-semibold "
          >
            Source Language
          </p>
          <select
            id="translate"
            name="translate"
            className="h-4.5 w-[225px] p-1 block border border-[#C4CAD4]-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={props.inputLanguage}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="bengali">Bengali</option>
            <option value="english">English</option>
            <option value="gujarati">Gujarati</option>
            <option value="hindi">Hindi</option>
            <option value="kannada">Kannada</option>
            <option value="marathi">Marathi</option>
            <option value="oriya">Oriya</option>
            <option value="tamil">Tamil</option>
            <option value="telugu">Telugu</option>
          </select>
        </div>
      </div>
      <div
        id="uploadfile"
        className="h-[85%] w-[97%] flex border-dashed border-[#E2E2E3]-300 border-4 p-4 bg-white items-center justify-center"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {props.uploadedFileData === null ? (
          <div className="w-full grid items-center justify-center">
            <p className="text-[#958E8E] font-inter font-semibold text-lg">
              Drag files here to upload
            </p>
            <div className="w-full flex justify-center">
              <img src={FileCabinate}></img>
            </div>
            <p className="text-[#958E8E] font-inter font-semibold text-base">
              or,{" "}
              <label
                htmlFor="fileInput"
                className="text-[#106AF2] cursor-pointer"
              >
                browse{" "}
              </label>
              for a file on your computer
              <input
                type="file"
                id="fileInput"
                name="fileInput"
                style={{ display: "none" }}
                onChange={handleFileInput}
                accept=".pdf, .html"
              />
            </p>
          </div>
        ) : (
          <div
            id="disableClickDiv"
            className="w-full h-full p-3 overflow-scroll"
          >
            {props.fileType === "application/pdf" && props.uploadedFileData && (
              <embed
                src={props.uploadedFileData}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            )}
            {props.fileType === "text/html" && props.uploadedFileData && (
              <iframe
                title="HTML Content"
                srcDoc={props.uploadedFileData}
                width="100%"
                height="100%"
                sandbox="value"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Uploadfile;
