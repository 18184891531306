import { React, useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import voice from "../../assets/voice.png";
import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { environment } from "../../environment";
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("is_admin")) {
      navigate("/dashboard");
    }
  }, []);
  const signInFunction = (event) => {
    event.preventDefault();
    let payload = {
      email: formValues.email,
      password: formValues.password,
    };

    axios
      .post(`${environment}/api/userManagement/login`, payload)
      .then((response) => {
        if (response && response.status == 200) {
          const admin = response?.data?.user?.is_admin === 1 ? true : false;
          const token = response?.data?.user?.token;
          const email = response?.data?.user?.email;
          const firstName = response?.data?.user?.firstName;
          const lastName = response?.data?.user?.lastName;
          dispatch(setToast(true));
          dispatch(setToastMessage(["success", "Login Successful"]));
          localStorage.setItem("is_admin", admin);
          localStorage.setItem("token", token);
          localStorage.setItem("email", email);
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data;
        if (errorMessage) {
          if (errorMessage.includes("Internal Server Error")) {
            dispatch(setToast(true));
            dispatch(setToastMessage(["error", "Internal Server Error"]));
          } else {
            dispatch(setToast(true));
            dispatch(setToastMessage(["error", errorMessage]));
          }
        }
      });
  };

  return (
    <div className="grid grid-cols-2 h-screen">
      <div className="bg-[#1C4C96] flex-col col-span-2 md:col-span-1 px-4 py-8">
        <img src={logo} alt="Logo" />
        <img src={voice} alt="Voice" className="px-2" />
        <div className="flex gap-2 justify-center p-12 items-center">
          <p className="text-[#FFF] text-lg italic font-medium font-sans">
            Transforming Words, Empowering Minds:
          </p>
          <p className="text-[#EB2325] text-4xl italic font-medium font-sans mb-1.5">
            ShabdAI
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col col-span-2 md:col-span-1">
        <form
          className="flex flex-col gap-4 w-full p-[20%]"
          onSubmit={signInFunction}
        >
          <div>
            <p className="text-[#1C4C96] text-2xl font-medium font-sans text-start">
              Welcome back
            </p>
            <p className="text-[#EB2325] text-sm font-medium font-sans text-start">
              Enter the email and password for your account
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-[#1C4C96] text-xl font-medium font-sans text-start">
              Email
            </p>
            <TextField
              size="small"
              required
              value={formValues.email}
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-[#1C4C96] text-xl font-medium font-sans text-start">
              Password
            </p>
            <TextField
              size="small"
              required
              value={formValues.password}
              onChange={(e) =>
                setFormValues({ ...formValues, password: e.target.value })
              }
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Button className="bg-[#1C4C96] text-[#FFF] mt-8" type="submit">
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
