import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./playground.css";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";

const columns = [
  { field: "firstName", headerName: "First name", sortable: false, flex: 1 },
  { field: "lastName", headerName: "Last name", sortable: false, flex: 1 },
  {
    field: "email",
    headerName: "Email",
    description: "Email of the Users.",
    sortable: false,
    width: 160,
    flex: 1,
    color: "red",
  },
];

export default function UsersData({
  searchValue,
  totalData,
  setSelectedUsers,
}) {
  const [usersData, setUsersData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    setUsersData(totalData);
    setSelectionModel([]); // Reset selectionModel to an empty array
  }, [totalData]);

  const handleSelectedRows = (newSelectionModel) => {
    setSelectedUsers(
      totalData.filter((item) => newSelectionModel.includes(item.id))
    );
    setSelectionModel(newSelectionModel);
  };

  return (
    <div style={{ height: "calc(100vh - 165px)", width: "100%" }}>
      <DataGrid
        rows={usersData?.filter(
          (item) =>
            item?.firstName
              ?.toLowerCase()
              .startsWith(searchValue.toLowerCase()) ||
            item?.lastName
              ?.toLowerCase()
              .startsWith(searchValue.toLowerCase()) ||
            item?.email?.toLowerCase().startsWith(searchValue.toLowerCase())
        )}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newSelectionModel) => {
          handleSelectedRows(newSelectionModel);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[10, 20]}
        disableRowSelectionOnClick
        selectionModel={selectionModel}
      />
    </div>
  );
}
