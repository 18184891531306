import { Button, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UsersData from "./UsersData";
import { useEffect, useState } from "react";
import NewUserDialog from "../popup/NewUserDialog";
import EditUserDialog from "../popup/EditUserDialog";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";
import DeleteUserDialog from "../popup/DeleteUserDialog";
import { environment } from "../../environment";
import { useNavigate } from "react-router-dom";
const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []);
  const openEdit = () => {
    if (selectedUsers.length === 1) {
      setOpenEditDialog(true);
    } else {
      dispatch(setToast(true));
      dispatch(setToastMessage(["success", "Select one row to continue."]));
    }
  };
  const openDelete = () => {
    if (selectedUsers.length > 0) {
      setOpenDeleteDialog(true);
    } else {
      dispatch(setToast(true));
      dispatch(setToastMessage(["success", "Select one row to continue."]));
    }
  };
  const addNewUser = (payload) => {
    axios
      .post(`${environment}/api/userManagement/addUsers`, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if(res.status===403){
          navigate("/login");
          dispatch(setToast(true));
          dispatch(
            setToastMessage(["error", "Session expired , Please login again"])
          );
        }
        if (res && res.status === 200) {
          dispatch(setToast(true));
          dispatch(setToastMessage(["success", "User Added Successfully"]));
          getAllUsers();
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data;
        if (errorMessage) {
          dispatch(setToast(true));
          dispatch(setToastMessage(["error", errorMessage]));
        }
      });
  };
  const getAllUsers = () => {
    axios
      .get(`${environment}/api/userManagement/getAllUsers`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if(response.status===403){
          navigate("/login");
          dispatch(setToast(true));
          dispatch(
            setToastMessage(["error", "Session expired , Please login again"])
          );
        }
        if (response && response.status === 200) {
          let onlyUsers = response?.data?.users.filter((item) => !item.is_admin)
          setUsersData(onlyUsers);
        }
      });
  };
  const editUser = (payload) => {
    axios
      .put(
        `${environment}/api/userManagement/alterUserInfo`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if(res.status===403){
          navigate("/login");
          dispatch(setToast(true));
          dispatch(
            setToastMessage(["error", "Session expired , Please login again"])
          );
        }
        if (res && res.status === 200) {
          dispatch(setToast(true));
          dispatch(setToastMessage(["success", "User Edited Successfully"]));
          getAllUsers();
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data;
        if (errorMessage) {
          dispatch(setToast(true));
          dispatch(setToastMessage(["error", errorMessage]));
        }
      });
  };
  const deleteUsers = async () => {
    const deleteAllUsers = selectedUsers.map((user) => {
      let currentId = user.id;
      return axios.delete(
        `${environment}/api/userManagement/deleteUser?id=${currentId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    });
    try {
      await Promise.all(deleteAllUsers);
      dispatch(setToast(true));
      dispatch(setToastMessage(["success", "Users Deleted Successfully"]));
      getAllUsers();
    } catch (error) {
      getAllUsers();
      const errorMessage = error?.response?.data;
      if (errorMessage) {
        dispatch(setToast(true));
        dispatch(setToastMessage(["error", errorMessage]));
      }
    }
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <TextField
          size="small"
          placeholder="Search by username"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div className="flex gap-2">
          {/* <Button
            className="border border-[#1C4C96] text-[#1C4C96] text-sm flex gap-1 hover:text-[#FFF] hover:bg-[#1C4C96]"
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={() => openEdit()}
          >
            <EditIcon className="h-4" />
          </Button> */}
          <Button
            className="border border-[#1C4C96] text-[#1C4C96] text-sm flex gap-1 hover:text-[#FFF] hover:bg-[#1C4C96]"
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={() => openDelete()}
          >
            <DeleteIcon className="h-4" />
          </Button>
          <Button
            className="border border-[#1C4C96] text-[#1C4C96] text-sm flex gap-1 hover:text-[#FFF] hover:bg-[#1C4C96]"
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={() => setOpenNewDialog(true)}
          >
            Add User
            <PersonAddAlt1Icon className="h-4 w-4 mb-0.5" />
          </Button>
        </div>
      </div>
      <UsersData
        searchValue={searchValue}
        totalData={usersData}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <NewUserDialog
        open={openNewDialog}
        setOpen={setOpenNewDialog}
        addNewUser={addNewUser}
        totalData={usersData}
      />
      {selectedUsers.length === 1 && (
        <EditUserDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          editUser={editUser}
          selectedUsers={selectedUsers}
        />
      )}
      {selectedUsers.length > 0 && (
        <DeleteUserDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          deleteUsers={deleteUsers}
        />
      )}
    </div>
  );
};

export default UsersList;
