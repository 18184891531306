import { createSlice } from "@reduxjs/toolkit";

export const TranslateStore = createSlice({
  name: "model",
  initialState: {
    translate: "bhashini",
    inputLanguage: "",
    outputLanguage: "",
    uploadedFile: null,
    fileResponse: "",
    isFeatching: false,
    fileType: "",
    fileName: "",
    uploadFileData: null,
    translatedData: "",
    updatedFileType: "",
    displayMsg:"",
  },
  reducers: {
    setTranslate(state, action) {
      state.translate = action.payload;
    },
    setInputLanguage(state, action) {
      state.inputLanguage = action.payload;
    },
    setOutputLanguage(state, action) {
      state.outputLanguage = action.payload;
    },
    setUploadedFile(state, action) {
      state.uploadedFile = action.payload;
    },
    setFeatching(state, action) {
      state.isFeatching = action.payload;
    },
    setFiletype(state, action) {
      state.fileType = action.payload;
    },
    setFileName(state, action) {
      state.fileName = action.payload;
    },
    setFileResponseData(state, action) {
      state.fileResponse = action.payload;
    },
    setUploadedFileData(state, action) {
      state.uploadFileData = action.payload;
    },

    setUpdatedFileType(state, action) {
      state.updatedFileType = action.payload;
    },
    setTranslatedData(state, action) {
      state.translatedData = action.payload;
    },
    setLoadDisplayMsg(state, action) {
      state.displayMsg = action.payload;
    },
    resetState: (state) => {
      return {
        translate: "",
        inputLanguage: "",
        outputLanguage: "",
        uploadedFile: null,
        fileResponse: "",
        isFeatching: false,
        fileType: "",
        fileName: "",
        uploadFileData: null,
        translatedData: "",
        updatedFileType: "",
        displayMsg:""
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTranslate,
  setInputLanguage,
  setOutputLanguage,
  setUploadedFile,
  setFiletype,
  setFileName,
  setFeatching,
  setFileResponseData,
  setUploadedFileData,
  setUpdatedFileType,
  setTranslatedData,
  resetState,
  setLoadDisplayMsg
} = TranslateStore.actions;

export default TranslateStore.reducer;
