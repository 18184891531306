import "./App.css";
import { HashRouter,Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setToast, setToastMessage } from "./store/slices/TriggerSlice";
import Dashboard from "./pages/Dashboard";

function App() {
  const toaster = useSelector((state) => state.triggers.toast);
  const toastMessage = useSelector((state) => state.triggers.toastMessage);
  const dispatch = useDispatch();
  useEffect(() => {
    if (toaster && toastMessage) {
      const type = toastMessage[0];
      const message = toastMessage[1];
      toast[type](message);
    }
    dispatch(setToast(false));
    dispatch(setToastMessage(null));
  }, [toaster, toastMessage]);

  return (
    <div className="App">
      <Toaster
        position="top-center"
        reverseOrder={false}
        className="Custom-toaster"
      />
      <HashRouter>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/*" element={<Navigate replace to="/login"/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
