import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
export default function DeleteUserDialog({ open, setOpen, deleteUsers }) {
  const handleClose = () => {
    setOpen(false);
  };
  const deleteUser = () => {
    handleClose();
    deleteUsers();
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle className="dialogTitle">
          Delete User <PersonRemoveIcon />
        </DialogTitle>
        <DialogContent className="dialogContent">
          <div className="formColumn">
            <p className="labelTextLarge">
              Are you sure you want to delete these users?
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: "24px" }}>
          <Button
            className="cancelDialog"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="saveDialog"
            size="small"
            onClick={() => deleteUser()}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
