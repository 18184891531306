import React, { useEffect, useState, useRef } from "react";
import OutputPlaceholder from "../../assets/Dashboard/Output_placeholder.svg";
import Downloadsvg from "../../assets/Dashboard/Download.svg";
import { useDispatch, useSelector } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";
import FileSaver, { saveAs } from "file-saver";
import { BarLoader } from "react-spinners";
import {
  setOutputLanguage,
  setTranslatedData,
} from "../../store/slices/TranslateStore";
import Viewer from "react-file-viewer";

const Outputfile = (props) => {
  const dispatch = useDispatch();
  const fileResponse = useSelector((state) => state.model.fileResponse);
  const translatedData = useSelector((state) => state.model.translatedData);
  const displayMsg = useSelector((state) => state.model.displayMsg);
  const handleChange = (event) => {
    dispatch(setOutputLanguage(event.target.value));
  };

  const handledownloadas = (e) => {
    if (fileResponse) {
      if (props.fileType === "application/pdf") {
        const binaryData = new Blob([fileResponse.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        FileSaver.saveAs(
          binaryData,
          `${props.fileName.replace(/\.[^/.]+$/, "")}_output.docx`
        );
        dispatch(setToast(true));
        dispatch(setToastMessage(["success", "Successfully downloaded"]));
      } else if (props.fileType === "text/html") {
        const blob = new Blob([fileResponse.data], {
          type: "text/html",
        });
        FileSaver.saveAs(
          blob,
          `${props.fileName.replace(/\.[^/.]+$/, "")}_output.html`
        );
        dispatch(setToast(true));
        dispatch(setToastMessage(["success", "Successfully downloaded"]));
      }
    } else {
      dispatch(setToast(true));
      dispatch(setToastMessage(["error", "No data to download"]));
    }
  };

  useEffect(() => {
    if (fileResponse && props.fileType === "text/html") {
      dispatch(setTranslatedData(fileResponse.data));
    } else if (fileResponse && props.fileType === "application/pdf") {
      const binaryData = new Blob([fileResponse?.data]);
      const blob = new Blob([binaryData], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const blobUrl = URL.createObjectURL(blob);
      dispatch(setTranslatedData(blobUrl));
    }
  }, [fileResponse, props.fileType]);

  return (
    <div className="pl-4 w-[100%] md:w-[50%] h-full gap-6">
      <div className="h-[70px] w-full flex items-center justify-between">
        <div>
          <p
            htmlFor="translate"
            className="text-[#1C4C9C] w-[124px] h-7 font-semibold "
          >
            Target language
          </p>
          <select
            id="translate"
            name="translate"
            className="h-4.5 w-[225px] p-1 block border border-[#C4CAD4]-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={props.outputLanguage}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="bengali">Bengali</option>
            <option value="english">English</option>
            <option value="gujarati">Gujarati</option>
            <option value="hindi">Hindi</option>
            <option value="kannada">Kannada</option>
            <option value="marathi">Marathi</option>
            <option value="oriya">Oriya</option>
            <option value="tamil">Tamil</option>
            <option value="telugu">Telugu</option>
          </select>
        </div>
        <button
          onClick={(e) => {
            handledownloadas(e);
          }}
        >
          <img src={Downloadsvg}></img>
        </button>
      </div>
      <div className="h-[85%] w-[100%] flex border-dashed border-[#E2E2E3]-300 border-4 p-4 bg-white items-center justify-center">
        {props.isFetching && (
          <div className="w-full h-full flex items-center justify-center">
            <div className="w-full grid items-center justify-center gap-4">
              <BarLoader
                size={250}
                color={"#1C4C96"}
                loading={props.isFetching}
                className=" w-full flex items-center mx-auto justify-center"
              />
              {displayMsg && (
                <p className="text-[#1C4C9C] w-full  h-7 font-semibold font-mono">
                  {displayMsg}
                </p>
              )}
            </div>
          </div>
        )}
        {fileResponse == "" && !props.isFetching ? (
          <div className="w-full grid items-center justify-center">
            <div className="w-full flex justify-center">
              <img src={OutputPlaceholder}></img>
            </div>
            <p className="text-[#958E8E] font-inter font-semibold text-base">
              Explore the translated contents of your file in this section
            </p>
          </div>
        ) : (
          translatedData && (
            <>
              {props.fileType === "application/pdf" && !props.isFetching && (
                <div className="w-full h-full">
                  <Viewer fileType="docx" filePath={translatedData} />
                </div>
              )}
              {props.fileType === "text/html" && !props.isFetching && (
                <div className="w-full h-full">
                  <iframe
                    title="HTML Content"
                    id="htmlContent"
                    srcDoc={translatedData}
                    width="100%"
                    height="100%"
                    sandbox="value"
                  />
                </div>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};
export default Outputfile;
