import { useEffect, useState } from "react";
import LeftBar from "../../components/leftBar";
import Playground from "../../components/playground";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState("Translation");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("is_admin")) {
      navigate("/login");
      dispatch(setToast(true));
      dispatch(setToastMessage(["error", "Session Expired, Login again!"]));
    }
  }, []);
  return (
    <div className="w-full h-screen flex">
      <LeftBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <Playground selectedTab={selectedTab} />
    </div>
  );
};

export default Dashboard;
