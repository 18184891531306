import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TextField } from "@mui/material";

const Profile = () => {
  return (
    <div className="w-full h-full">
      <div className="gradient-box left"></div>
      <div className="gradient-box right"></div>
      <div className="w-full flex items-center justify-center h-full">
        <div className="border border-[#1C4C96] bg-[#FFF] w-[476px] p-6 flex flex-col gap-8 rounded-lg">
          <div>
            <AccountCircleIcon className="h-24 w-24 cursor-pointer rounded text-[#1C4C96]" />
            <p className="text-base text-[#1C4C59] font-mono">
              <b>Role:</b> {localStorage.getItem("is_admin") === "true" ? "Admin" : "User"}
            </p>
          </div>
          <div className="flex flex-col justify-center text-start px-8 gap-1">
            <p className="text-base text-[#1C4C59] font-medium font-sans flex-row row-span-1">
              Email:
            </p>
            <TextField
             size="small"
             placeholder="abc@gmail.com"
             required
             value={localStorage.getItem("email")}
             disabled
             autoComplete="new-password"
             // className="flex-row row-span-1"
             fullWidth
            />
          </div>
          <div className="flex flex-col justify-center text-start px-8 gap-1">
            <p className="text-base text-[#1C4C59] font-medium font-sans flex-row row-span-1">
              First Name:
            </p>
            <TextField
              size="small"
              placeholder="abc@gmail.com"
              required
              value={localStorage.getItem("firstName")}
              disabled
              autoComplete="new-password"
              // className="flex-row row-span-1"
              fullWidth
            />
          </div>
          <div className="flex flex-col justify-center text-start px-8 gap-1">
            <p className="text-base text-[#1C4C59] font-medium font-sans flex-row row-span-1">
              Last Name:
            </p>
            <TextField
              size="small"
              placeholder="abc@gmail.com"
              required
              value={localStorage.getItem("lastName")}
              disabled
              autoComplete="new-password"
              // className="flex-row row-span-1"
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
