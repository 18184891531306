import React, { useEffect, useState } from "react";
import Translatesvg from "../../assets/Dashboard/Translate.svg";
import Uploadfile from "./Uploadfile";
import Outputfile from "./Outputfile";
import Translator from "../../assets/Dashboard/Translator.svg";
import { useDispatch, useSelector } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";
import axios from "axios";
import {
  setFeatching,
  setTranslate,
  setFileResponseData,
  setUpdatedFileType,
  setTranslatedData,
  setLoadDisplayMsg,
} from "../../store/slices/TranslateStore";
import { useRef } from "react";
import { environment } from "../../environment";
import { useNavigate } from "react-router-dom";

const Translate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useSelector((state) => state.model.translate);
  const inputLanguage = useSelector((state) => state.model.inputLanguage);
  const outputLanguage = useSelector((state) => state.model.outputLanguage);
  const uploadedFileData = useSelector((state) => state.model.uploadFileData);
  const uploadFiles = useSelector((state) => state.model.uploadedFile);
  const fileName = useSelector((state) => state.model.fileName);
  const fileType = useSelector((state) => state.model.fileType);
  const isFetching = useSelector((state) => state.model.isFeatching);
  const updateFileTye = useSelector((state) => state.model.updatedFileType);
  const timeByFileSize = useRef(null);

  useEffect(() => {
    if (fileType === "text/html" && translate === "gpt") {
      dispatch(setTranslate("bhashini"));
    }
    if (fileType && !translate) {
      dispatch(setTranslate("bhashini"));
    }
  }, [fileType]);

  const handleChange = (event) => {
    dispatch(setTranslate(event.target.value));
  };

  const handleTranslate = async (e) => {
    if (translate === "") {
      dispatch(setToast(true));
      dispatch(setToastMessage(["error", "Please select translator"]));
    } else if (inputLanguage === "") {
      dispatch(setToast(true));
      dispatch(setToastMessage(["error", "Please provide input language"]));
    } else if (outputLanguage === "") {
      dispatch(setToast(true));
      dispatch(setToastMessage(["error", "Please provide output language"]));
    } else if (fileType === "") {
      dispatch(setToast(true));
      dispatch(setToastMessage(["error", "Please upload file"]));
    } else {
      dispatch(setTranslatedData(""));
      dispatch(setFileResponseData(""));
      let type = fileType === "application/pdf" ? "pdf" : "html";
      const formData = new FormData();
      formData.append("file", uploadFiles);
      formData.append("fileLanguages", inputLanguage);
      formData.append("targetLanguage", outputLanguage);
      formData.append("fileFormat", type);
      if(localStorage.getItem("is_admin") === "true"){
        formData.append("model", translate);
      }
      try {
        dispatch(setFeatching(true));
        timeByFileSize.current = setTimeout(() => {
          dispatch(
            setLoadDisplayMsg("It's taking longer than expected, Please wait!")
          );
        }, 30000);
        axios
          .post(
            `${environment}/api/translate`,
            formData,
            type === "pdf"
              ? {
                  responseType: "arraybuffer",
                  headers: {
                    Authorization: localStorage.getItem("token"),
                  },
                }
              : {
                  headers: {
                    Authorization: localStorage.getItem("token"),
                  },
                }
          )
          .then((res) => {
            if(res.status===403){
              navigate("/login");
              dispatch(setToast(true));
              dispatch(
                setToastMessage(["error", "Session expired , Please login again"])
              );
            }
            if (res) {
              clearTimeout(timeByFileSize.current);
              dispatch(setLoadDisplayMsg(""));
              dispatch(setFeatching(false));
              dispatch(setFileResponseData(res));
              dispatch(setUpdatedFileType(fileType));
              dispatch(setToast(true));
              dispatch(
                setToastMessage(["success", "File translated successfully"])
              );
            }
          })
          .catch((err) => {
            dispatch(setLoadDisplayMsg(""));
            dispatch(setFeatching(false));
            if (err.response) {
              if (err?.response?.data instanceof ArrayBuffer) {
                const textDecoder = new TextDecoder("utf-8");
                const decodedString = textDecoder.decode(err.response.data);
                dispatch(setToast(true));
                dispatch(setToastMessage(["error", decodedString]));
              } else if (
                err?.response?.data.includes("502") ||
                err?.response?.data.toLowerCase().includes("bad gateway") ||
                err?.response?.data.toLowerCase().includes("nginx")
              ) {
                dispatch(setToast(true));
                dispatch(
                  setToastMessage([
                    "error",
                    "There might be a network issue, please try again",
                  ])
                );
              } else {
                dispatch(setToast(true));
                let msg = "";
                msg = err?.response?.data ? err?.response.data : "";
                dispatch(setToastMessage(["error", msg]));
              }
            } else {
              dispatch(setToast(true));
              dispatch(
                setToastMessage([
                  "error",
                  "There might be a network issue, please try again",
                ])
              );
            }
          });
      } catch (error) {
        dispatch(setFeatching(false));
        dispatch(setLoadDisplayMsg(""));
      }
    }
  };

  return (
    <div className="gap-1 w-full h-full">
      <div className="flex h-12">
        {localStorage.getItem("is_admin") === "true" && (
          <div className="flex">
            <p
              htmlFor="translate"
              className="text-[#1C4C9C] w-[88px] h-7 font-semibold flex items-center"
            >
              Translator :
            </p>
            <select
              id="translate"
              name="translate"
              className="h-[32px] w-[225px] p-1 block border border-[#C4CAD4]-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={translate}
              onChange={handleChange}
            >
              <option value="bhashini">Bhashini</option>
              <option value="googleTranslate">Google translate</option>
              {fileType !== "text/html" && <option value="gpt">GPT</option>}
            </select>
          </div>
        )}
        <div className="flex w-full justify-end mr-4">
          <button
            className={`text-[#ffff] flex w-[108px] h-8 ml-2 ${
              isFetching ? `bg-[#8ebeed]` : `bg-[#1C4C96]`
            } border border-[#1C4C96]-300 rounded-md items-center justify-center gap-1`}
            onClick={(e) => handleTranslate(e)}
            disabled={isFetching}
          >
            <img src={Translatesvg} alt=""></img>
            Translate
          </button>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row h-[92%]  border border-[#C4CAD4]-300 rounded-md bg-[#F5F5F5] p-4 ">
        <Uploadfile
          inputLanguage={inputLanguage}
          uploadFile={uploadFiles}
          fileName={fileName}
          fileType={fileType}
          uploadedFileData={uploadedFileData}
        />
        <div className="h-full w-[5%] flex items-center">
          <img src={Translator} alt=""></img>
        </div>
        <Outputfile
          outputLanguage={outputLanguage}
          uploadFile={uploadFiles}
          fileType={updateFileTye}
          fileName={fileName}
          isFetching={isFetching}
        />
      </div>
    </div>
  );
};

export default Translate;
