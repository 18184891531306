import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MuiTelInput } from "mui-tel-input";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
export default function NewUserDialog({
  open,
  setOpen,
  addNewUser,
  totalData,
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [phoneHelper, setPhoneHelper] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    if (phoneNumber && phoneNumber?.includes("+91")) {
      const indianPhoneNumberRegex = /^\+91[6-9]\d{9}$/;
      const stringWithoutSpaces = phoneNumber?.replace(/\s/g, "");
      return indianPhoneNumberRegex.test(stringWithoutSpaces);
    } else if (phoneNumber && !phoneNumber.includes("+91")) {
      return true;
    } else {
      return false;
    }
  };
  const addUser = (e) => {
    e.preventDefault();
    let payload = {
      users: [
        {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
          password: formValues.password,
          isAdmin: 0,
          token: localStorage.getItem("token"),
          phone_number: formValues.phoneNumber,
        },
      ],
    };

    if (!isValidPhoneNumber(formValues.phoneNumber)) {
      setPhoneHelper(true);
      return;
    } else {
      setPhoneHelper(false);
      addNewUser(payload);
      setFormValues({});
      handleClose();
    }
  };
  React.useEffect(() => {
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
  }, [totalData]);
  const handleChangeNumber = (newValue) => {
    if (phoneHelper) {
      if (isValidPhoneNumber(newValue)) {
        setPhoneHelper(false);
      }
    }
    setFormValues({ ...formValues, phoneNumber: newValue });
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} className="mainDialog">
        <form onSubmit={addUser} className="formContainer">
          <DialogTitle className="dialogTitle">Add User</DialogTitle>
          <DialogContent className="dialogContent">
            <div className="formColumn">
              <p className="labelText">Work Email</p>
              <TextField
                size="small"
                type="email"
                placeholder="abc@gmail.com"
                required
                value={formValues.email}
                onChange={(e) =>
                  setFormValues({ ...formValues, email: e.target.value })
                }
                autoComplete="new-password"
              />
            </div>
            <div className="flexing">
              <div className="formColumnHalf">
                <p className="labelText">First Name</p>
                <TextField
                  size="small"
                  required
                  value={formValues.firstName}
                  onChange={(e) =>
                    setFormValues({ ...formValues, firstName: e.target.value })
                  }
                  autoComplete="new-password"
                  fullWidth
                />
              </div>
              <div className="formColumnHalf">
                <p className="labelText">Last Name</p>
                <TextField
                  size="small"
                  required
                  value={formValues.lastName}
                  onChange={(e) =>
                    setFormValues({ ...formValues, lastName: e.target.value })
                  }
                  autoComplete="new-password"
                />
              </div>
            </div>

            <div className="formColumn">
              <p className="labelText">Password</p>
              <TextField
                autoComplete="new-password"
                placeholder="*****************"
                size="small"
                required
                value={formValues.password}
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="formColumn">
              <p className="labelText">Phone number</p>
              <MuiTelInput
                value={formValues.phoneNumber}
                onChange={handleChangeNumber}
                className="h-10"
                defaultCountry="IN"
              />
              {phoneHelper && (
                <p
                  style={{ fontSize: "12px", color: "#828C95" }}
                  className="text-xs text-slate-600 font-normal"
                >
                  Invalid Phone number
                </p>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ paddingRight: "24px", marginTop: "2px" }}>
            <Button className="cancelDialog" size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="saveDialog" size="small" type="submit">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
