import TranslateIcon from "@mui/icons-material/Translate";
import SettingsIcon from "@mui/icons-material/Settings";
import UserMenu from "./UserMenu";

const LeftBar = ({ selectedTab, setSelectedTab }) => {
  const hovered = "bg-[#FEF1E9] text-[#1C4C96]";

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <div className="flex-col h-full z-10 col-span-1 bg-[#1C4C96] w-[48px]">
      <div className="flex flex-col py-12 items-center h-full justify-between">
        <div className="flex flex-col gap-4">
          <TranslateIcon
            className={` h-8 w-12 cursor-pointer  ${
              selectedTab === "Translation"
                ? hovered
                : "hover:bg-[#FEF1E9] hover:text-[#1C4C96] text-white"
            }`}
            onClick={() => handleTabClick("Translation")}
          />
          {localStorage.getItem("is_admin") === "true" && (
            <SettingsIcon
              className={` h-8 w-12 cursor-pointer  ${
                selectedTab === "User Management"
                  ? hovered
                  : "hover:bg-[#FEF1E9] hover:text-[#1C4C96] text-white"
              }`}
              onClick={() => handleTabClick("User Management")}
            />
          )}
        </div>
        <div>
          <UserMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
