import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";

export default function EditUserDialog({
  open,
  setOpen,
  editUser,
  selectedUsers,
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  useEffect(() => {
    if (selectedUsers.length === 1) {
      let selectedUser = selectedUsers[0];
      setFormValues(selectedUser);
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const editUserDetails = (e) => {
    e.preventDefault();
    let payload = {
      ...formValues,
    };
    editUser(payload);
    handleClose();
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form onSubmit={editUserDetails} className="formContainer">
          <DialogTitle className="dialogTitle">
            Edit User <PersonIcon />
          </DialogTitle>
          <DialogContent className="dialogContent">
            <div className="formColumn">
              <p className="labelText">Work Email</p>
              <TextField
                size="small"
                placeholder="abc@gmail.com"
                required
                value={formValues.email}
                onChange={(e) =>
                  setFormValues({ ...formValues, email: e.target.value })
                }
                autoComplete="new-password"
              />
            </div>
            <div className="flexing">
              <div className="formColumnHalf">
                <p className="labelText">First Name</p>
                <TextField
                  size="small"
                  required
                  value={formValues.firstName}
                  onChange={(e) =>
                    setFormValues({ ...formValues, firstName: e.target.value })
                  }
                  autoComplete="new-password"
                  fullWidth
                />
              </div>
              <div className="formColumnHalf">
                <p className="labelText">Last Name</p>
                <TextField
                  size="small"
                  required
                  value={formValues.lastName}
                  onChange={(e) =>
                    setFormValues({ ...formValues, lastName: e.target.value })
                  }
                  autoComplete="new-password"
                />
              </div>
            </div>
            {selectedUsers[0].is_admin === 0 &&
              <div className="formColumn">
                <p className="labelText">Password</p>
                <TextField
                  autoComplete="new-password"
                  placeholder="*****************"
                  size="small"
                  required
                  value={formValues.password ? formValues.password : ""}
                  onChange={(e) =>
                    setFormValues({ ...formValues, password: e.target.value })
                  }
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            }
          </DialogContent>
          <DialogActions style={{ paddingRight: "24px" }}>
            <Button className="cancelDialog" size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="saveDialog" size="small" type="submit">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
