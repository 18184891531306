import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch } from "react-redux";
import { setToast, setToastMessage } from "../../store/slices/TriggerSlice";
import { useNavigate } from "react-router";
import { resetState } from "../../store/slices/TranslateStore";

export default function UserMenu({ selectedTab, setSelectedTab }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const hovered = "bg-[#FEF1E9] text-[#1C4C96]";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const changeToggle = (screen) => {
    if (screen === "Profile") {
      setSelectedTab(screen);
      handleClose();
    } else {
      dispatch(setToast(true));
      dispatch(setToastMessage(["success", "Successfully logged out"]));
      dispatch(resetState());
      localStorage.clear();
      navigate("/login");
    }
  };
  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <AccountCircleIcon
            className={` h-8 w-12 cursor-pointer rounded ${
              selectedTab === "Profile" || open
                ? hovered
                : "hover:bg-[#FEF1E9] hover:text-[#1C4C96] text-white"
            }`}
          />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "right-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => changeToggle("Profile")}>
                      My Account
                    </MenuItem>
                    <MenuItem onClick={() => changeToggle("Log out")}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
