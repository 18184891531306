import SignIn from "../../components/signin/SignIn";

const Login = () => {
  return (
    <div>
      <SignIn />
    </div>
  );
};

export default Login;
